import { UserManager } from "oidc-react";
import { WebStorageStateStore } from "oidc-client";

import { getPostLoginUri, history } from "../utils";

export const userManager = new UserManager({
  userStore: new WebStorageStateStore({ store: localStorage }),
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_SECRET,
  response_type: 'code',
  redirect_uri: process.env.REACT_APP_URL,
  post_logout_redirect_uri: process.env.REACT_APP_URL,
  popup_redirect_uri: process.env.REACT_APP_URL,
  scope: process.env.REACT_APP_SCOPE,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_URL,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  validateSubOnSilentRenew: true,
});


// userManager.getUser().then(user => {
//   console.log("logged in user", user?.profile)
// }).catch(err => {
//   console.log("err")
// })

export const oidcConfig = {
  onSignIn: async (_user: any) => {
    const prevPath = getPostLoginUri(true);

    if(!!prevPath) {
      history.push(prevPath);
    } else {
      history.push("/");
      window.location.hash = "";
    }
  },
  userManager: userManager
};