import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Helmet } from "react-helmet";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { Animation, Dropdown } from "../";
import { onlyForRoles, useUserContext } from "../../context";
import { useToggle } from "../../hooks";
import { cx, Permission } from "../../utils";
import { DropdownOption } from "./DropdownOption";
import { ILanguage } from "./interface";

// TODO: this component is not used anymore
interface ISettingsDropdownWrapper {
  changeLanguage: (code: string, lang: ILanguage) => void;
  onSignOut: () => void;
  currentLangObj: ILanguage;
  languages: ILanguage[];
  html: string;
  css: string;
}

const SettingsDropdownWrapper: FC<ISettingsDropdownWrapper> = ({
  onSignOut,
  changeLanguage,
  currentLangObj,
  languages,
  html,
  css,
}) => {
  const { admin, appsEditor, usersEditor } = Permission;

  const { user, picture } = useUserContext();
  const { email } = !!user && user;

  const isUserAdmin = onlyForRoles([admin, appsEditor, usersEditor]);
  const { toggle, visible } = useToggle();
  const { t } = useTranslation();

  const onItemClick = () => {
    document.body.click();
  };

  const portaDashboard = process.env.REACT_APP_ADMIN_PAGE;

  const scope = {
    portaDashboard,
    onItemClick,
    toggle,
    visible,
    t,
    onSignOut,
    isUserAdmin,
    changeLanguage,
    currentLangObj,
    user,
    picture,
    email,
    NavLink,
    Dropdown,
    DropdownOption,
    cx,
    Icon,
    Animation,
    languages,
  };

  return (
    <>
      <Helmet>
        <style>{css}</style>
      </Helmet>
      <LiveProvider code={html || "<></>"} scope={scope}>
        <LivePreview />
        <LiveError />
      </LiveProvider>
    </>
  );
};

export default SettingsDropdownWrapper;
